import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { Spinner } from '../spinner';
import { useKeyboardNav } from '@/hooks/use-keyboard-nav';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

export const Button = ({
  className,
  children,
  disabled,
  useKeyboard,
  loading,
  inverted,
  showArrow,
  onClick,
  size,
  ...rest
}: any) => {
  const isDisabled = disabled || loading;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useKeyboard) useKeyboardNav(onClick, isDisabled);

  return (
    <button
      className={cx(className, 'button', { inverted }, size)}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      {loading ? (
        <Spinner className={cx('spinner', { inverted })} />
      ) : (
        <div className={cx('children', { loading })}>
          {children}
          {showArrow && <HiArrowRight />}
        </div>
      )}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  useKeyboard: PropTypes.bool,
  loading: PropTypes.bool,
  inverted: PropTypes.bool,
  showArrow: PropTypes.bool,
  style: PropTypes.object,
  size: PropTypes.oneOf(['sm'])
};
