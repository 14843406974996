import Head from 'next/head';
import { APP_NAME } from '@/constants/app';
import { ConnectVisual } from './components/connect-visual';
import styles from './styles.module.css';

export const AuthLayout = ({ children, title, showConnect = true }) => {
  return (
    <>
      <Head>
        <title>{title ? `${title} - ${APP_NAME}` : APP_NAME}</title>
      </Head>
      <div className={styles.container}>
        <div className={styles.content}>
          {showConnect && <ConnectVisual />}

          {children}
        </div>
      </div>
    </>
  );
};
