import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { HiExclamationCircle } from 'react-icons/hi';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

/* Wraps a input component with an overlaying floating label */
export const WithFloatingLabel = ({
  children,
  hasFocus,
  hasValue,
  label,
  invalid,
  disabled,
  validationText,
  validationClassName
}) => {
  const floatingMode = hasFocus || hasValue;
  return (
    <div className={styles.wrapper}>
      {children}
      {invalid && <HiExclamationCircle className={cx('invalidIcon')} />}
      <label
        className={cx('floating-label', {
          floating: floatingMode,
          invalid,
          disabled
        })}
      >
        {label}
      </label>
      <span
        className={cx('focus-border', {
          focus: floatingMode,
          invalid,
          disabled
        })}
      ></span>
      {invalid && (
        <span className={cx('validation-text', validationClassName)}>{validationText}</span>
      )}
    </div>
  );
};

WithFloatingLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  hasFocus: PropTypes.bool,
  hasValue: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  validationText: PropTypes.string,
  validationClassName: PropTypes.string
};
