import axios from 'axios';

export const RESOURCES = {
  AUTH: 'auth',
  LOGIN: 'login',
  REQUEST_RESET: 'request-reset',
  RESET: 'reset'
};

const AUTH_ENDPOINT = `${process.env.NEXT_PUBLIC_GARAGESHARE_URI}/${RESOURCES.AUTH}`;

const ENDPOINTS = {
  LOGIN: `${AUTH_ENDPOINT}/${RESOURCES.LOGIN}`,
  REQUEST_RESET: `${AUTH_ENDPOINT}/${RESOURCES.REQUEST_RESET}`,
  RESET: `${AUTH_ENDPOINT}/${RESOURCES.RESET}`
};

/**
 * Sign in a user given their email and password.
 */
export const login = async (email: string, password: string) => {
  type Response = {
    token: string;
  };

  return axios.post<Response>(ENDPOINTS.LOGIN, { email, password });
};

/**
 * Request a password reset
 */
export const requestReset = async (email: string) => {
  return axios.post(ENDPOINTS.REQUEST_RESET, { email });
};

/**
 * Reset a password.
 */
export const reset = async (data: { password: string; token: string }) => {
  return axios.post(ENDPOINTS.RESET, data);
};
