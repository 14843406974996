import Image from 'next/image';
import React from 'react';
import { HiSwitchHorizontal, HiUser } from 'react-icons/hi';
import styles from './styles.module.css';

export const ConnectVisual = () => (
  <div className={styles.wrapper}>
    <div className={styles.logo}>
      <Image
        src="https://res.cloudinary.com/woopgroup/image/upload/v1658409379/app/assets/w_bluedot_v55hkx.png"
        alt="Woop Logo"
        priority={true}
        width={50}
        height={20}
      />
    </div>

    <HiSwitchHorizontal className={styles.connect} />
    <HiUser className={styles.avatar} />
  </div>
);
