import PropTypes from 'prop-types';
import React from 'react';
import { BaseTextField } from './base-text-field';

export const TextField = ({
  name,
  value,
  isValid,
  label,
  validationText,
  showValidation,
  inputType,
  save,
  disabled,
  onBlur,
  ...props
}: any) => {
  return (
    <BaseTextField
      type={inputType}
      name={name}
      label={label}
      value={value || ''}
      isValid={isValid}
      validationText={validationText}
      showValidation={showValidation}
      save={save}
      disabled={disabled}
      onBlur={onBlur}
      {...props}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  validationText: PropTypes.string,
  showValidation: PropTypes.bool,
  inputType: PropTypes.string,
  save: PropTypes.func,
  onBlur: PropTypes.func
};
