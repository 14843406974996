import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { DEFAULT_VALIDATION_MESSAGE } from '../../../constants/validation';
import { WithFloatingLabel } from '../with-floating-label';
import styles from './styles.module.css';

const cx = classnames.bind(styles);

// eslint-disable-next-line react/display-name
export const BaseTextField = forwardRef(
  (
    {
      name,
      isValid,
      type = 'text',
      label,
      value,
      showValidation,
      validationText = DEFAULT_VALIDATION_MESSAGE,
      disabled,
      onBlur,
      save,
      ...props
    }: any,
    ref
  ) => {
    const [showValidity, setShowValidity] = useState(showValidation);
    const [focus, setFocus] = useState(false);

    return (
      <WithFloatingLabel
        label={label}
        hasValue={!!value}
        invalid={!isValid && (showValidation || (showValidity && !!value))}
        hasFocus={focus}
        validationText={validationText}
        disabled={disabled}
      >
        <input
          ref={ref}
          type={type}
          name={name}
          disabled={disabled}
          className={cx('input', {
            hidePlaceholder: !!label,
            invalid: !isValid
          })}
          value={value}
          onBlur={() => {
            setShowValidity(true);
            setFocus(false);
            onBlur && onBlur();
          }}
          onFocus={() => setFocus(true)}
          onChange={e => save(e.target.value)}
          {...props}
        />
      </WithFloatingLabel>
    );
  }
);

BaseTextField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  showValidation: PropTypes.bool,
  validationText: PropTypes.string,
  onBlur: PropTypes.func,
  save: PropTypes.func
};
