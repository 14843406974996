import { useCallback, useEffect } from 'react';

export const KEYS = {
  ENTER: 'Enter',
  ESC: 'Escape'
};

/**
 * Hook for listening for a keypress event.
 *
 * @param {Function} onKeyDown
 * @param {boolean} disabled
 * @param {string} triggerKey The key name, e.g. Enter.
 */
export const useKeyboardNav = (onKeyDown, disabled, triggerKey = KEYS.ENTER) => {
  const nav = useCallback(
    e => {
      if (!disabled && e.key === triggerKey) {
        e.preventDefault();
        e.stopPropagation();
        onKeyDown && onKeyDown(e);
      }
    },
    [disabled, onKeyDown, triggerKey]
  );

  useEffect(() => {
    if (disabled) return;
    document.body.addEventListener('keydown', nav);
    return () => document.body.removeEventListener('keydown', nav);
  }, [disabled, onKeyDown, nav]);
};
