import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const cx = classnames.bind(styles);

export const Spinner = ({ className }) => (
  <div className={cx('wrapper', className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 120 120"
      enableBackground="new 0 0 120 120"
      xmlSpace="preserve"
    >
      <path d="M34.365,86.643l0.553,0.529l-9.743,9.746l-0.53-0.514c-0.354-0.344-0.704-0.695-1.048-1.049l-0.517-0.529l9.743-9.742  l0.53,0.549C33.683,85.975,34.022,86.314,34.365,86.643z M9.271,58.518l-0.015,0.921C9.253,59.625,9.25,59.812,9.25,60  s0.003,0.374,0.007,0.561l0.015,0.92h13.773L23.022,60l0.022-1.481H9.271z M96.953,61.48h13.775l0.015-0.92  c0.003-0.187,0.007-0.374,0.007-0.561s-0.004-0.374-0.007-0.561l-0.015-0.921H97.718l-0.765,0.006l0.016,0.765  c0.004,0.237,0.009,0.473,0.009,0.711s-0.005,0.476-0.01,0.717L96.953,61.48z M94.824,96.918l0.531-0.514  c0.354-0.344,0.703-0.695,1.048-1.049l0.517-0.529l-9.742-9.742l-0.53,0.549c-0.33,0.342-0.668,0.682-1.013,1.012l-0.555,0.529  L94.824,96.918z M24.645,23.596c-0.355,0.344-0.704,0.692-1.049,1.048l-0.516,0.53l9.744,9.742l0.53-0.552  c0.33-0.344,0.669-0.681,1.012-1.013l0.549-0.53l-9.74-9.74L24.645,23.596z M60.713,23.034l0.769,0.016V9.264L59.998,9.25  l-1.479,0.014l0.003,13.784l0.765-0.015C59.766,23.024,60.242,23.024,60.713,23.034z M58.519,110.73l1.479,0.02l1.48-0.02V96.953  l-0.881,0.016c-0.198,0.004-0.397,0.006-0.6,0.006l-1.479-0.02V110.73z M87.176,34.917l9.744-9.742l-0.516-0.53  c-0.346-0.355-0.694-0.704-1.049-1.048l-0.531-0.515l-9.744,9.743l0.553,0.529c0.346,0.331,0.684,0.668,1.014,1.011L87.176,34.917z" />
    </svg>
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string
};
