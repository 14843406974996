import { DEFAULT_LIMIT } from '@/api/vehicles';

export const ROUTES = {
  HOME: '/',
  VEHICLES: '/vehicles',
  SUPPORT: '/support',
  FAQ: '/faq',

  // Auth routes
  AUTH_LOGIN: '/auth/login',
  AUTH_PASSWORD_REQUEST: '/auth/password/request',
  AUTH_PASSWORD_RESET: '/auth/password/reset',
  AUTH_LOGOUT: '/auth/logout',
  IV: '/iv'
} as const;

export const DEFAULT_VEHICLE_ROUTE = `${ROUTES.VEHICLES}?offset=0&limit=${DEFAULT_LIMIT}`;
