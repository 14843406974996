import axios from 'axios';
import { COOKIES } from 'woop-shared/constants/cookies';
import { GsVehicle } from 'woop-shared/db/types';
import { getAuthHeader } from 'woop-shared/utils/auth';
import { getCookie } from 'woop-shared/utils/cookies';
import { VehicleResponse } from './responses';

export const ENDPOINTS = {
  VEHICLES: `${process.env.NEXT_PUBLIC_GARAGESHARE_URI}/vehicles`,
  EXPORT: `${process.env.NEXT_PUBLIC_GARAGESHARE_URI}/vehicles/export`,
  CHARTS: `${process.env.NEXT_PUBLIC_GARAGESHARE_URI}/vehicles/charts`,
  SEARCH_OPTIONS: `${process.env.NEXT_PUBLIC_GARAGESHARE_URI}/vehicles/search-options`
};

export const DEFAULT_LIMIT = 25;
export const DEFAULT_VEHICLE_URL = `${ENDPOINTS.VEHICLES}?limit=${DEFAULT_LIMIT}`;

/**
 * @returns A paginated vehicles response
 */
export const getVehicles = async (jwt: string, url: string = DEFAULT_VEHICLE_URL) => {
  type Response = {
    totalMatches: number;
    data: GsVehicle[];
  };

  return axios.get<Response>(url, {
    headers: getAuthHeader(jwt)
  });
};

/**
 * @param url A fetch vehicles url with query params added
 */
export const exportVehicles = async (url: string) => {
  const jwt = getCookie(COOKIES.JWT);
  if (!jwt) return null;

  const res = await axios.get<string>(url, {
    headers: getAuthHeader(jwt)
  });

  return res.data;
};

export const getVehicle = async (jwt: string, id: string) => {
  return axios.get<VehicleResponse>(`${ENDPOINTS.VEHICLES}/${id}`, {
    headers: getAuthHeader(jwt)
  });
};

export const patchVehicle = async (id: string, updates: object) => {
  const jwt = getCookie(COOKIES.JWT);
  if (!jwt) return;
  return axios.patch<VehicleResponse>(`${ENDPOINTS.VEHICLES}/${id}`, updates, {
    headers: getAuthHeader(jwt)
  });
};

export const getVehicleCharts = async () => {
  type Response = {
    leaseChart: number[];
    loanChart: number[];
    makeChart: { make: string; count: number }[];
    sourceCounts: { sold: number; sourced: number };
    ownershipStatusCounts: { LOANED: number; LEASED: number; OWNED: number };
  };

  const jwt = getCookie(COOKIES.JWT);
  if (!jwt) return null;

  return axios.get<Response>(ENDPOINTS.CHARTS, {
    headers: getAuthHeader(jwt)
  });
};

export const getVehicleOptions = async ({ year, make }) => {
  type Response = {
    makes: string[];
    models: string[];
  };

  const jwt = getCookie(COOKIES.JWT);

  const url = new URL(ENDPOINTS.SEARCH_OPTIONS);
  if (year) url.searchParams.append('year', year);
  if (make) url.searchParams.append('make', make);
  return axios.get<Response>(url.toString(), {
    headers: getAuthHeader(jwt)
  });
};
